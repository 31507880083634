import React, { useState } from 'react';
import axios from 'axios';
import logo from '../../Assets/Logo.svg';
import http from '../../Assets/http.svg';
import converting from '../../Assets/convert.svg';
import download from '../../Assets/download.svg';
import desktop from '../../Assets/desktop-white.svg';
import wave from '../../Assets/wave-white.svg';
import tahometer from '../../Assets/tachometer-white.svg';
import nonuser from '../../Assets/non-user-white.svg';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import styled, { createGlobalStyle } from 'styled-components';
import './Home.css';

const stripePromise = loadStripe('pk_live_51Ped7qE9mHm04vVCp3fDYKtgHPUfw4CM7gILnMobx56EBW1tnnFzfM8cPOOG41VUYFanndiFg9S7TlzLLL4R1NhQ00BRlMhhVq');
//const api = "http://localhost:8080"
const api = "https://www.easy-mp3.xyz"

function Home() {

  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [progress, setProgress] = useState(0);
  const [errorMsg, setErrorMsg] = useState('');
  const [title, setTitle] = useState('');
  const [paymentSuccessfull, setPaymentSuccessfull] = useState(false);
  const [paymentError, setPaymentError] = useState(false);

  const handleSearch = async () => {
    setLoading(true);
    try {
      const response = await axios.get(api + '/api/Audio/DownloadMp3', {
        params: { youtubeUrl: youtubeUrl },
        responseType: 'blob' // Important to specify the response type as blob to handle binary data
      });

      // Create a URL for the Download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      setDownloadUrl(url);

    } catch (error) {
      console.error('Error downloading the MP3 file', error);
    }
    setLoading(false);
  };


  const handleSearch2 = async () => {
    setLoading(true);
    try {
      const response = await axios.get(api + '/api/Audio/Status', {
        params: { youtubeUrl: youtubeUrl }
      });

      console.log(response)

      const { title, status, progress, msg } = response.data;

      if (status === 'processing') {
        setProgress(progress);
        setTitle(title);
        setTimeout(handleSearch2, 1000); // Check again after 3 seconds
      } else if (status === 'ok') {
        setTitle(title);
        setProgress(100);

        const response = await fetch(api + '/api/Payments/create-payment-intent', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            PaymentMethod: "",
            VideoUrl: youtubeUrl,
            Type: "Ad"
          }),
        });

        const paymentIntent = await response.json();
        handlePaymentSuccess(paymentIntent.fileUrl);

        setLoading(false);
      } else {
        setErrorMsg(msg);
        setLoading(false);
      }
    } catch (error) {
      setErrorMsg('An error occurred while checking the process status.');
      setLoading(false);
    }

  };

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', title + '.mp3');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handlePaymentSuccess = (link) => {
    setDownloadUrl(link)
    setPaymentSuccessfull(true);
  };

  const handlePaymentError = (error) => {
    setPaymentError(true);
  };

  return (
    <main className="mp3-page">

      <section className="top-section">
        <div className="top-section-image">
          <img src={logo} alt="Easy MP3 logo image" onClick={() => { window.location.href = '/'; }} />
        </div>

        <div className="top-section-searchbar">
          <div className='top-section-searchbar-text'>
            {progress != 0 ? title : 'Please insert a valid YouTube URL'}
          </div>
          <div className="search-container">
            {!loading && progress != 100 && (
              <>
                <input
                  type="url"
                  id="search"
                  placeholder="https://www.youtube.com/watch?v=r6DGgLBwa9U"
                  value={youtubeUrl}
                  onChange={(e) => setYoutubeUrl(e.target.value)}
                />
                <button
                  className="search-container-button"
                  onClick={handleSearch2}
                >
                  Convert
                </button>
              </>
            )}
            {loading && (
              <>
                <div className="spinner"></div>
                <p>Progress: {progress}%</p>
              </>
              // Show spinner while loading
            )}
            {/* {!loading && progress == 100 && !paymentSuccessfull && !paymentError && (
              <>
                <Elements stripe={stripePromise}>
                  <StripeForm
                    onPaymentSuccess={handlePaymentSuccess}
                    onPaymentError={handlePaymentError}
                    videoUrl={youtubeUrl}
                  />
                </Elements>

              </>
            )} */}
            {paymentSuccessfull && (
              <>
                {/* <button className="convert-again-button" onClick={handleConvertAgain}>Convert Again</button> */}
                <button className="download-button" onClick={handleDownload}>Download</button>
              </>
            )}
            {paymentError && (
              <>
                <p>Oops! Looks like something went wrong! Try again!</p>
              </>
            )}

          </div>
        </div>
        <div className="top-section-promo-text">
          <div className="top-section-promo-text-title">
            The Most Trusted YouTube to Mp3 Converter
          </div>
          <div className="top-section-promo-text-bottom">
            Convert your favorite YouTube videos to a downloadable mp3 file in seconds
          </div>
        </div>
        <div className="top-section-guide">
          <div className="top-section-guide-element arrow">
            <img src={http} alt="Image displaying HTTP link" width="86" height="" />
            <p className="top-section-guide-element-title">Paste the YouTube Link</p>
            <p className="top-section-guide-element-text">Copy the URL of the YouTube video you want to convert and paste it into the link box above.</p>
          </div>
          <div className="top-section-guide-element arrow">
            <img src={converting} alt="Image displaying converting icon" width="46" height="" />
            <p className="top-section-guide-element-title">Click ‘Convert’</p>
            <p className="top-section-guide-element-text">Use our YouTube to MP3 converter by clicking the “Convert” button. The process is quick and easy, ensuring your MP3 file is ready in no time.</p>
          </div>
          <div className="top-section-guide-element">
            <img src={download} alt="Image displaying converting icon" width="46" height="" />
            <p className="top-section-guide-element-title">Download Your MP3</p>
            <p className="top-section-guide-element-text">After the conversion is complete, click the “Download” button to save the MP3 file directly to your device.</p>
          </div>
        </div>
      </section>
      <section className="bottom-section">
        <div className="bottom-section-upper">
          <div className="bottom-section-upper-title">No Hidden Fees or Subscriptions.</div>
          <div className="bottom-section-upper-text">Experience the convenience of Easy-MP3 today. Try our YouTube to MP3 converter and enjoy your favorite YouTube content in MP3 format, hassle-free!</div>
        </div>
        <div className="bottom-section-benefits-container">
          <div className="bottom-section-benefits-element">

            <div>
              <img src={desktop} alt="Image displaying desktop monitor" loading='lazy' />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                User-Friendly Interface
              </div>
              <div className="bottom-section-benefits-element-text">
                No technical skills required. Our converter is designed to be easy for everyone.
              </div>
            </div>

          </div>

          <div className="bottom-section-benefits-element">

            <div>
              <img src={wave} alt="Image displaying wave form" loading='lazy' />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                High-Quality Audio
              </div>
              <div className="bottom-section-benefits-element-text">
                Enjoy clear and crisp audio with every conversion.
              </div>
            </div>

          </div>

          <div className="bottom-section-benefits-element">

            <div>
              <img src={tahometer} alt="Image displaying tachometer" />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                Fast Conversion
              </div>
              <div className="bottom-section-benefits-element-text">
                Get your MP3 files in seconds using our YouTube to MP3 converter.
              </div>
            </div>

          </div>

          <div className="bottom-section-benefits-element">

            <div>
              <img src={nonuser} alt="Image displaying user icon" />
            </div>
            <div className="bottom-section-benefits-element-bottom">
              <div className="bottom-section-benefits-element-title">
                No Account Needed
              </div>
              <div className="bottom-section-benefits-element-text">
                Start converting without the hassle of signing up.
              </div>
            </div>

          </div>

        </div>

        <div className='footer'>
          <p className='footer-element element' onClick={() => { window.location.href = '/'; }}>&copy; Easy MP3</p>
          <p className='footer-element element' onClick={() => { window.location.href = '/privacy-policy'; }}>Privacy Policy</p>
          <p className='footer-element element' onClick={() => { window.location.href = '/terms'; }}>Terms of Use</p>
          <p className='footer-element element' onClick={() => { window.location.href = '/copyright'; }}>Copyright</p>
        </div>
      </section>
    </main>

  );
}

export default Home;



const StripeForm = ({ onPaymentSuccess, onPaymentError, videoUrl }) => {
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (!error) {
      const response = await fetch(api + '/api/Payments/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          PaymentMethod: paymentMethod.id,
          VideoUrl: videoUrl,
          Type: "Ad"
        }),
      });

      const paymentIntent = await response.json();

      if (paymentIntent.status === 'succeeded') {
        onPaymentSuccess(paymentIntent.fileUrl);
      } else {
        onPaymentError(paymentIntent.status);
      }
    } else {
      onPaymentError(error);
    }

  };

  return (
    <Container>
      <Heading>Complete Your Transaction: $0.99</Heading>
      <form onSubmit={handleSubmit}>
        <StyledCardElement />
        <Button type="submit" disabled={!stripe}>
          Pay
        </Button>
      </form>
    </Container>
  );
};


const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
`;

const Container = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 100%;
  margin: 1rem;
`;

const Heading = styled.h1`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;

const StyledCardElement = styled(CardElement)`
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 1rem;
`;

const Button = styled.button`
  background: #6772e5;
  color: white;
  border: none;
  padding: 0.75rem;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:disabled {
    background: #bbbbbb;
    cursor: not-allowed;
  }
`;